import React from "react";
import styled from "@emotion/styled";
import DocumentationPageLayout from "@src/components/DocumentationPageLayout";
import { Box, Flex } from "@src/components/Boxes";
import window from "global/window";

const CookiesPolicyContent = () => {
  const LinkButton = styled("button")`
    border: 0;
    background: transparent;
    font-weight: bold;
    color: #00bcf2;
    cursor: pointer;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  `;
  return (
    <div className="col-sm-12 col-md-9">
      <div className="page-section">
        <Flex alignItems="start">
          <Box width={["100%", "100%", 10 / 12]}>
            <div>
              <p className="lead">Last Updated: April 25, 2022</p>
              <p>
                ClassDojo offers a range of features that use technologies like cookies, pixel tags ("pixels"), device
                or other identifiers, and local storage (collectively, “Online Tracking Technologies”) to provide a
                seamless and safe experience on ClassDojo. Some of the technologies described below are used solely on
                our informational website located at{" "}
                <a href="https://www.classdojo.com" target="_blank" rel="noreferrer">
                  https://www.classdojo.com
                </a>{" "}
                (the “ClassDojo Website”) and others are used only in logged-in areas of the Service, such as through
                the ClassDojo Platform or ClassDojo App. Any terms not defined here shall have the meaning set forth in
                the ClassDojo{" "}
                <a href="https://www.classdojo.com/privacy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                .
              </p>
              <p>
                First and foremost, we do not use or disclose information collected through Online Tracking Technology
                for{" "}
                <a
                  href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d"
                  target="_blank"
                  rel="noreferrer"
                >
                  third-party advertising
                </a>{" "}
                purposes (including{" "}
                <a
                  href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54"
                  target="_blank"
                  rel="noreferrer"
                >
                  behaviorally targeting advertising
                </a>{" "}
                to students or children) on the Service. As described in more detail below, we use these technologies in
                many ways so that ClassDojo works, like for security purposes, to provide certain helpful features, and
                also to understand and measure how ClassDojo is used by teachers, parents, and students. Third parties
                that you interact with as you use the Service may also use these technologies for a variety of purposes.
              </p>
              <p>
                For a full list of all the different tracking technologies we use, please see{" "}
                <a href="#FullListCookies">here.</a>{" "}
                <strong>
                  Check back here from time to time to get the latest information about these technologies and how they
                  are used on ClassDojo.
                </strong>
              </p>
              <p>
                Your browser or device may offer settings related to these technologies - visit your browser or device's
                help material to learn about your options. We may, however, not recognize or respond to browser or
                device signals around tracking, and changing these settings may impact your ability to use some of the
                great features on ClassDojo.
              </p>
              <p>Below we provide more information about Online Tracking Technologies and how they work.</p>
            </div>
          </Box>
        </Flex>

        <div className="page-section legal-content">
          <div>
            <hr />
            <h3>Quick Reference:</h3>
            <ul>
              <li>
                <a href="#OnlineTrackingTechnologies">What are some of these Online Tracking Technologies?</a>
              </li>
              <li>
                <a href="#MobiledevicesOnlineTrackingTechnologies">
                  How do we use Online Tracking Technologies when you use a mobile device?
                </a>
              </li>
              <li>
                <a href="#OnlineTrackingTechnologiesManagement">How can I manage these Online Tracking Technologies?</a>
              </li>
              <li>
                {" "}
                <a href="#CookiesAndSimilarTechnologiesUsage">Why do we use Online Tracking Technologies?</a>
              </li>
              <li>
                <a href="#UsageOfOnlineTrackingTechnologiesByThirdParties">
                  How do third parties use Online Tracking Technologies on our Service and the ClassDojo Website?
                </a>
              </li>
              <li>
                {" "}
                <a href="#ControlTrackingShowAds">
                  How can you control ClassDojo’s use of Online Tracking Technologies to show you ads?
                </a>{" "}
              </li>
              <li>
                <a href="#CookiesFromUnregisteredUsers">Online Tracking Technologies from unregistered users</a>
              </li>
              <li>
                <a href="#FullListCookies">Detailed list of Online Tracking Technologies</a>
              </li>
            </ul>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#OnlineTrackingTechnologies">
                What are some of these Online Tracking Technologies?
              </a>
            </h3>
            <div className="collapse legal-body" id="OnlineTrackingTechnologies">
              <div>
                <ul>
                  <li>
                    <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
                      <span>Cookies and similar technologies</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703#h_b865d3eb-6e41-467c-9b53-7f2c9bd73562">
                      <span>Pixel Tags</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703#h_efdb3ae6-8829-4e77-a8e0-b47c865fbe1b">
                      <span>Local Storage</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#MobiledevicesOnlineTrackingTechnologies">
                How do we use Online Tracking Technologies when you use a mobile device?
              </a>
            </h3>
            <div className="collapse legal-body" id="MobiledevicesOnlineTrackingTechnologies">
              <div>
                <p>
                  Many mobile devices act just like computers, such as smartphones that support full-featured browsers.
                  For those devices, we use Online Tracking Technologies in ways that are similar to when you are
                  accessing the web from a computer. Other devices use different technologies such as local storage on a
                  device, Software Development Kits (SDKs), or Advanced Programming Interfaces (APIs) to facilitate the
                  same types of processes we use Cookies for, as described below. As on the web, we also may use similar
                  technologies to store and retrieve an identifier or other information from or on your device. For
                  example, with your permission, we may use an advertising identifier such as the Identifier Advertiser
                  (“IDFA”) on iOS devices and the Advertising ID on Android devices where cookies are not available.
                </p>

                <p>
                  With your permission, we may also obtain or receive information about your use of your mobile device
                  and our Service. We describe how we use these technologies and the information we received from them
                  in our{" "}
                  <a href="https://www.classdojo.com/privacy/" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
            <hr />

            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#OnlineTrackingTechnologiesManagement">
                How can I manage these Online Tracking Technologies?
              </a>
            </h3>
            <div className="collapse legal-body" id="OnlineTrackingTechnologiesManagement">
              <div>
                <ul>
                  <li>
                    <strong>Browser Cookie Controls</strong>
                    <br />
                    <span>
                      Your browser or device may offer settings related to these technologies - visit your browser or
                      device's help menu to learn about your options (browsers for mobile devices may not offer
                      visibility). For example, you may set your browser to refuse all cookies or to indicate when a
                      cookie is being sent. However, changing these settings may impact your use of some features or
                      services on our Service or the ClassDojo Website as they may not function properly without
                      cookies.
                    </span>
                    <br />
                    <span>
                      While some internet browsers offer a “do not track” or “DNT” option that lets you tell websites
                      that you do not want to have your online activities tracked, these features are not yet uniform
                      and there is no common standard adopted by industry groups, technology companies, or regulators.
                      Therefore, we do not currently commit to responding to browsers’ DNT signals with respect to our
                      websites. ClassDojo takes privacy and meaningful choice seriously and will make efforts to
                      continue to monitor developments around DNT browser technology and the implementation of a
                      standard.
                    </span>
                  </li>
                  <li>
                    <strong>ClassDojo Cookie Preference Center:</strong>
                    <br />
                    <span>
                      You will also have certain options for management of Online Tracking Technologies as set forth in
                      the applicable jurisdiction-specific{" "}
                      <LinkButton onClick={window && window.OneTrust ? window.OneTrust.ToggleInfoDisplay : undefined}>
                        ClassDojo Cookie Settings Preference Center
                      </LinkButton>
                      .
                    </span>
                  </li>
                  <li>
                    <strong>Flash Cookies</strong>
                    <br />
                    <span>
                      Cookie management tools provided by your browser will not remove Flash cookies. Learn how to
                    </span>{" "}
                    <a
                      href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      manage privacy and storage settings for Flash cookies
                    </a>
                    <span>
                      . If you disable Flash cookies, you won’t have access to many features that make your guest
                      experience more efficient and some of our Services or the ClassDojo Website will not function
                      properly.
                    </span>
                  </li>
                  <li>
                    <strong>Mobile</strong>
                    <br />
                    <span>
                      Please note that the choices described above regarding browser-based opt-outs may not apply to
                      mobile applications. Your mobile device operating system may offer settings that enable you to
                      make choices about the collection of mobile app information for targeted advertising (such as{" "}
                      <a href="https://support.apple.com/en-us/HT202074" target="_blank" rel="noopener noreferrer">
                        turning off personalized ads
                      </a>
                      ). Mobile app users may also download through the applicable app store the AppChoices app to learn
                      about how you can opt-out of the collection of cross-app data on this device for targeted
                      advertising by participating companies. Since not all of the partners we work with will be
                      available in AppChoices, you should exercise your choices using both mobile device settings and
                      AppChoices. If you opt-out, you will continue to receive the same number of mobile ads, but they
                      may be less relevant because they will not be based on your interests. You may still see ads
                      related to the content on a web page or in an application or based on other non-personal
                      information. Please see “How can you control ClassDojo’s use of Online Tracking Technologies to
                      show you ads?” for more information.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#CookiesAndSimilarTechnologiesUsage">
                Why do we use Online Tracking Technologies?
              </a>
            </h3>
            <div className="collapse legal-body" id="CookiesAndSimilarTechnologiesUsage">
              <div>
                <p>
                  ClassDojo uses cookies that are session-based (temporary) and persistent-based (permanent). Session
                  cookies exist only during one session. They disappear from your computer when you close your browser
                  software or turn off your computer. Persistent cookies remain on your computer or mobile device even
                  after you close your browser or turn off your computer and make it possible to recognize you during
                  subsequent visits to the website or application. Persistent cookies remain on your device until their
                  end date is reached until a new cookie is installed or until you delete them in the settings of your
                  browser, application, or mobile device.
                </p>
                <p>
                  We use these technologies for a variety of reasons, such as allowing us to show you content that’s
                  most relevant to you, improving our products and services, and helping to keep our Service and the
                  ClassDojo Website secure. Please visit <a href="#FullListCookies">here</a> to see the list of Online
                  Tracking Technologies used on ClassDojo.com. Please note that this list is not updated daily. While
                  specific names of the Online Tracking Technologies that we use may change from time to time as we
                  improve and update our Service or the ClassDojo Website, they generally fall into the below categories
                  of use:
                </p>
              </div>

              <table className="cookies-info">
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>Categories of use</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>Descriptions</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>Manage Settings</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Strictly Necessary -</strong>
                        <br />
                        Authentication
                      </p>
                    </td>
                    <td>
                      <p>
                        These tell us when you’re logged in, so we can show you the appropriate experience and features.
                        For example, cookies help us remember your browser so you do not have to keep logging into our
                        Site.
                      </p>
                    </td>
                    <td>
                      <p>
                        Please see <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Strictly Necessary -</strong>
                        <br />
                        Security and site integrity
                      </p>
                    </td>
                    <td>
                      <p>
                        These help keep ClassDojo safe and secure by supporting and enabling security features and help
                        us detect activity that violates our Terms of Service or otherwise degrades our ability to
                        provide our Site and services. For example: We use security cookies to authenticate users,
                        prevent fraudulent use of login credentials, protect user data from unauthorized parties, and
                        help us fight spam and phishing attacks.
                      </p>
                    </td>
                    <td>
                      <p>
                        Please see <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Functional -</strong>
                        <br />
                        Localization
                      </p>
                    </td>
                    <td>
                      <p>
                        These help us provide localized experiences. For example: We may store information in a cookie
                        that is placed on your browser or device so you will see the site in your preferred language.
                      </p>
                    </td>
                    <td>
                      <p>
                        Please see <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Functional -</strong>
                        <br />
                        Features and services
                      </p>
                    </td>
                    <td>
                      <p>
                        These provide functionality that helps us deliver products and services and change the way the
                        site behaves or looks. For example: Cookies help us store preferences, know when you’ve seen or
                        interacted with certain content, and provide you with customized content and experiences. For
                        instance, cookies allow us to make suggestions to you and others, and to customize content on
                        our Services or the ClassDojo Website.
                      </p>
                      <p>
                        Loss of the information stored in a preference cookie may make the Site experience less
                        functional but should not prevent it from working.
                      </p>
                    </td>
                    <td>
                      <p>
                        Please see <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p>
                        <strong>Performance</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        These help us ensure ClassDojo is operationally performing at a high level, such as by
                        understanding how quickly our Service or the ClassDojo Website loads for different users. For
                        example: these cookies help us route traffic between servers and understand how quickly certain
                        pages load for different people and if they receive error messages from certain pages. These
                        cookies do not collect information that individually identifies a visitor. These are used to
                        improve how the Service and ClassDojo Website functions and performs.
                      </p>
                    </td>
                    <td>
                      <p>
                        Please see <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Performance -</strong>
                        <br />
                        Analytics and research
                      </p>
                    </td>
                    <td>
                      <p>
                        From time to time, ClassDojo engages third parties to track and analyze usage and volume
                        statistical information from individuals who visit our Sites. ClassDojo may also utilize Flash
                        cookies for these purposes. These are used to understand how our Services and ClassDojo Website
                        visitors use and engage with ClassDojo, and thus so we can continue to find ways to make
                        ClassDojo a great experience. For example: We may use these technologies to know which features
                        on ClassDojo are most popular - and which ones could be improved
                      </p>
                    </td>
                    <td>
                      <p>
                        Please see <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Advertising</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        We use cookies to make advertising more engaging to visitors and to retarget ClassDojo visitors
                        on other sites (such as Facebook). Some common applications of cookies are to select advertising
                        based on what’s relevant to a visitor; to improve reporting on campaign performance, and to
                        avoid showing ads the visitor has already seen.{" "}
                        <strong>
                          We will NOT use advertising cookies in areas where students are logged in to the Services.
                        </strong>
                      </p>
                      <p>
                        For example: ClassDojo uses cookies delivered by third parties to show you ads for ClassDojo
                        products and services that we think may interest you on this and other devices you may use, and
                        to track the performance of ClassDojo advertisements. For example, these cookies remember which
                        browsers have visited the ClassDojo Website.
                      </p>
                      <p>
                        We may also work with third-party advertising companies that collect information about your
                        visits to non-affiliated mobile applications in order to show you advertisements that may
                        interest you on devices you may use. Please note that the choices described above regarding
                        browser-based targeted advertising may not apply to targeted advertising involving mobile
                        applications. Your mobile device operating system may offer settings that enable you to make
                        choices about the collection of mobile app information for targeted advertising.
                      </p>
                    </td>
                    <td>
                      <p>
                        You can opt-out of seeing online interest-based (or “targeted”) ads from ClassDojo and other
                        participating companies through the{" "}
                        <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank" rel="noopener noreferrer">
                          Digital Advertising Alliance
                        </a>{" "}
                        or the{" "}
                        <a href="https://optout.networkadvertising.org/?c=1" target="_blank" rel="noopener noreferrer">
                          Network Advertising Initiative
                        </a>{" "}
                        in the US, the{" "}
                        <a href="https://youradchoices.ca/" target="_blank" rel="noopener noreferrer">
                          Digital Advertising Alliance of Canada
                        </a>{" "}
                        in Canada, or the{" "}
                        <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">
                          European Interactive Digital Advertising Alliance
                        </a>{" "}
                        in Europe or through the ClassDojo Cookie Preference Center or your mobile device settings as
                        mentioned <a href="#OnlineTrackingTechnologiesManagement">above</a>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#UsageOfOnlineTrackingTechnologiesByThirdParties">
                How do third parties use Online Tracking Technologies on our Service and the ClassDojo Website?
              </a>
            </h3>
            <div className="collapse legal-body" id="UsageOfOnlineTrackingTechnologiesByThirdParties">
              <div>
                <p>
                  In addition to our own Online Tracking Technologies, we work with various companies to help us analyze
                  how our Service and the ClassDojo Website are used, for functionality and serving content, for
                  advertising, and to improve ClassDojo and deliver the best possible experience for teachers, parents,
                  and students.{" "}
                </p>
                <p>
                  {" "}
                  These third parties may use web measurement and customization technologies (such as cookies) in
                  conjunction with the provision of these services. Take a look at our{" "}
                  <a
                    href="https://www.classdojo.com/third-party-service-providers/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    third-party service providers
                  </a>{" "}
                  to learn more about them as well as to review their privacy policies. Additionally, please visit{" "}
                  <a href="#FullListCookies">here</a> to see the list of Online Tracking Technologies used on
                  ClassDojo.com. Please note that this list is not updated daily.
                </p>
                <p>
                  Third parties may also use cookies on their own website and applications in connection with the
                  ClassDojo Website or Services, such as social media sharing or authentication features from Facebook
                  and Twitter.
                </p>
              </div>
            </div>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#ControlTrackingShowAds">
                How can you control ClassDojo’s use of Online Tracking Technologies to show you ads?
              </a>
            </h3>
            <div className="collapse legal-body" id="ControlTrackingShowAds">
              <div>
                <p>
                  <strong>
                    We will NOT use advertising cookies in areas where students are logged in to the Services.
                  </strong>{" "}
                  We may partner with a third party to either display advertising on our website or manage our
                  advertising on other sites. Our third-party partner may use cookies or similar technologies in order
                  to provide you with advertising based on your browsing activities and interests. You can opt-out of
                  seeing online interest-based (or “targeted”) ads from ClassDojo and other participating companies
                  through the{" "}
                  <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank" rel="noopener noreferrer">
                    Digital Advertising Alliance
                  </a>{" "}
                  or the{" "}
                  <a href="https://optout.networkadvertising.org/?c=1" target="_blank" rel="noopener noreferrer">
                    Network Advertising Initiative
                  </a>{" "}
                  in the US, the{" "}
                  <a href="https://youradchoices.ca/" target="_blank" rel="noopener noreferrer">
                    Digital Advertising Alliance of Canada
                  </a>{" "}
                  in Canada, or the{" "}
                  <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">
                    European Interactive Digital Advertising Alliance
                  </a>{" "}
                  in Europe. Some of our advertising-related activities may involve the services of companies that do
                  not participate in those programs. For example, we may use the Google AdWords remarketing service to
                  advertise on third-party websites (including Google) to previous visitors to our ClassDojo Website.
                  You can set preferences for how Google advertises to you using the{" "}
                  <a
                    href="https://www.google.com/url?q=https://support.google.com/accounts/answer/2662856?hl%3Den%26co%3DGENIE.Platform%253DDesktop&sa=D&source=docs&ust=1651173935519060&usg=AOvVaw3pBAOynLbx0hGBOx8vEVSE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Ad Preferences
                  </a>{" "}
                  page. You will also have certain options for management of Online Tracking Technologies relating to
                  advertising as set forth in the applicable jurisdiction-specific{" "}
                  <LinkButton onClick={window && window.OneTrust ? window.OneTrust.ToggleInfoDisplay : undefined}>
                    ClassDojo Cookie Settings Preference Center
                  </LinkButton>
                  .
                </p>
                <p>
                  The opt-outs apply only to the browser in which you set them. For example, if you set the opt-out
                  while using Firefox, but then use Chrome, the opt-out will not be active in Chrome. To opt-out in
                  Chrome, you will need to repeat the opt-out process. Please note you may continue to receive generic
                  ads even after opting out.
                </p>
                <p>
                  We may also work with third-party advertising companies that collect information about your visits to
                  non-affiliated mobile applications in order to show you advertisements that may interest you on
                  devices you may use. Please note that the choices described above regarding browser-based targeted
                  advertising may not apply to targeted advertising involving mobile applications. Your mobile device
                  operating system may offer settings that enable you to make choices about the collection of mobile app
                  information for targeted advertising (for example, by re-setting your device’s advertising identifier
                  and/or such as{" "}
                  <a href="https://support.apple.com/en-us/HT202074" target="_blank" rel="noopener noreferrer">
                    turning off personalized ads
                  </a>
                  ). Mobile app users may also download through the applicable app store the AppChoices app to learn
                  about how you can opt-out of the collection of cross-app data on this device for targeted advertising
                  by participating companies. Since not all of the partners we work with will be available in
                  AppChoices, you should exercise your choices using both mobile device settings and AppChoices.
                </p>{" "}
              </div>
            </div>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#CookiesFromUnregisteredUsers">
                Online Tracking Technologies from unregistered users
              </a>
            </h3>
            <div className="collapse legal-body" id="CookiesFromUnregisteredUsers">
              <div>
                <p>
                  We still use Online Tracking Technologies if you don’t have an account or have logged out of your
                  account. For example, if you have logged out of your account we use cookies to, for example, help you
                  log in faster the next time.
                </p>

                <p>
                  We also set cookies if you don’t have a ClassDojo account, but have visited classdojo.com, to help us
                  protect the ClassDojo Website and the Service, and the people who use it from malicious activity. For
                  example, these cookies help us detect and prevent denial-of-service attacks and the mass creation of
                  fake accounts. If you have cookies on your browser or device, we read that cookie when you visit a
                  site with our app.
                </p>
              </div>
            </div>
            <hr />
            <h3 className="legal-toggle">
              <a className="toggle-link" data-toggle="collapse" href="#FullListCookies">
                Detailed list of Online Tracking Technologies in the Services and ClassDojo Website
              </a>
            </h3>
            <div className="collapse legal-body" id="FullListCookies">
              {/* <!-- OneTrust Cookies List start --> */}
              <div id="ot-sdk-cookie-policy"></div>
              {/* <!-- OneTrust Cookies List end --> */}
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

const content = {
  menu: `<ul className="list-unstyled hidden-xs sub-menu quick-reference-links">
                  <li><a className="depress-on-click" href="#OnlineTrackingTechnologies">Online Tracking Technologies</a></li>
                  <li><a className="depress-on-click" href="#MobiledevicesOnlineTrackingTechnologies">Mobile devices Online Tracking Technologies</a></li>
                  <li><a className="depress-on-click" href="#OnlineTrackingTechnologiesManagement">Online Tracking Technologies Management</a></li>
                  <li><a className="depress-on-click" href="#CookiesAndSimilarTechnologiesUsage">Cookies and similar technologies usage</a></li>
                  <li><a className="depress-on-click" href="#UsageOfOnlineTrackingTechnologiesByThirdParties">Usage of Online Tracking Technologies by third parties</a></li>
                  <li><a className="depress-on-click" href="#CookiesFromUnregisteredUsers">Cookies from unregistered users</a></li>
                </ul>`,
  componentContent: <CookiesPolicyContent />,
};

function CookiesPolicy() {
  return (
    <DocumentationPageLayout
      content={content}
      title="Cookies Policy"
      seoTitle="page_titles.cookies"
      seoDescription="page_descriptions.cookies"
      type="cookies"
    />
  );
}

export default CookiesPolicy;
